<template>
  <validation-observer
    ref="techTransferSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="techTransferSaveModal"
      body-class="position-static"
      centered
      size="lg"
      :title="isCreated ? 'Thêm chuyển giao công nghệ' : 'Cập nhật chuyển giao công nghệ'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên chuyển giao công nghệ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên chuyển giao công nghệ"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetTechTransfer.name"
                  name="name"
                  placeholder="Nhập tên chuyển giao công nghệ"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Thuộc nghiên cứu khoa học"
              label-for="researchId"
            >
              <v-select
                id="researchId"
                v-model="targetTechTransfer.researchId"
                label="name"
                :options="researches"
                :reduce="option => option.id"
                :disabled="!!researchId"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="form"
              label="Hình thức chuyển giao"
            >
              <b-form-input
                id="form"
                v-model="targetTechTransfer.form"
                name="form"
                placeholder="Nhập hình thức chuyển giao"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="method"
              label="Phương thức chuyển giao"
            >
              <b-form-input
                id="method"
                v-model="targetTechTransfer.method"
                name="method"
                placeholder="Nhập phương thức chuyển giao"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="hostUnit"
              label="Đơn vị chủ trì"
            >
              <b-form-input
                id="hostUnit"
                v-model="targetTechTransfer.hostUnit"
                name="hostUnit"
                placeholder="Nhập đơn vị chủ trì"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="participatingUnit"
              label="Đơn vị phối hợp"
            >
              <b-form-input
                id="participatingUnit"
                v-model="targetTechTransfer.participatingUnit"
                name="participatingUnit"
                placeholder="Nhập đơn vị phối hợp"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Đơn vị nhận chuyển giao"
              label-for="receivingUnit"
            >
              <b-form-input
                id="receivingUnit"
                v-model="targetTechTransfer.receivingUnit"
                name="receivingUnit"
                placeholder="Nhập đơn vị nhận chuyển giao"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Chủ sở hữu"
              label-for="proprietor"
            >
              <b-form-input
                id="proprietor"
                v-model="targetTechTransfer.proprietor"
                name="proprietor"
                placeholder="Nhập chủ sở hữu"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Chi phí thực hiện"
              label-for="totalCost"
            >
              <cleave
                id="totalCost"
                v-model="targetTechTransfer.totalCost"
                class="form-control"
                :options="numberOption"
                placeholder="Nhập chi phí thực hiện"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Thời gian thực hiện"
              label-for="totalTime"
            >
              <b-form-input
                id="totalTime"
                v-model="targetTechTransfer.totalTime"
                name="totalTime"
                placeholder="Nhập thời gian thực hiện"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Năm"
              label-for="year"
            >
              <datepicker
                id="year"
                v-model="targetTechTransfer.year"
                name="year"
                :format="yearFormat"
                minimum-view="year"
                input-class="year-select"
                clear-button-icon="fa fa-times"
                placeholder="Chọn năm"
                :bootstrap-styling="true"
                :clear-button="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('techTransferSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  name: 'TechTransferSave',
  components: {
    Cleave,
    vSelect,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    Datepicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    techTransfer: {
      type: Object,
      default: undefined,
    },
    researchId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetTechTransfer: {
        organizationId: null,
        name: '',
        researchId: null,
        hostUnit: '',
        participatingUnit: '',
        receivingUnit: '',
        totalCost: null,
        totalTime: '',
        year: null,
      },
      numberOption: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      yearFormat: 'yyyy',
      required,
    }
  },
  computed: {
    ...mapGetters({
      researches: 'techTransfer/researches',
    }),
    isCreated() {
      return !this.techTransfer
    },
  },
  methods: {
    ...mapActions({
      createTechTransfer: 'techTransfer/createTechTransfer',
      updateTechTransfer: 'techTransfer/updateTechTransfer',
      getAllResearchesByOrganization: 'techTransfer/getAllResearchesByOrganization',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.techTransfer) {
        this.targetTechTransfer = { ...this.techTransfer }
        if (this.targetTechTransfer.year) {
          this.targetTechTransfer.year = moment(`${this.targetTechTransfer.year}-01-01`, 'YYYY-MM-DD').toDate()
        }
      } else {
        this.targetTechTransfer = { ...this.targetTechTransfer, organizationId: this.user.orgId }
        if (this.researchId) {
          this.targetTechTransfer = { ...this.targetTechTransfer, researchId: this.researchId }
        }
      }
      this.isLoading = true
      try {
        await this.getAllResearchesByOrganization({ organizationId: this.user.orgId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .techTransferSaveFormRef
        .reset()
      this.targetTechTransfer = {
        organizationId: null,
        name: '',
        researchId: null,
        hostUnit: '',
        participatingUnit: '',
        receivingUnit: '',
        totalCost: null,
        totalTime: '',
        year: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .techTransferSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          if (this.targetTechTransfer.year) {
            this.targetTechTransfer.year = moment(this.targetTechTransfer.year).format('YYYY')
          }
          const response = this.isCreated
            ? await this.createTechTransfer(this.targetTechTransfer)
            : await this.updateTechTransfer(this.targetTechTransfer)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('techTransferSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.year-select {
  background-color: #fff !important;
}
</style>
